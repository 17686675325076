<!-- 视屏画面组件 -->
<template>
  <div class='videoBox'>
    <div class="nosources" v-if="videoMessage === ''">
      <span>无监控源</span>
    </div>
    <div class="videoPlace" v-else>
      <video class="videosource" ref="videoPlayers" id="videoBox" autoplay muted  @mouseenter="showMask=true" @mouseout="leaveMask" @dblclick="dblclick">
      </video>
      <div class="zhezhao" v-show="showMask" @mouseenter="showMask=true"  @mouseout="leaveMask">
        <span>{{ videoMessage.ChannelName }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import flv from 'flv.js'
export default {
  name: 'videoBox',
  components: {},
  props: ['videoMessage', 'idnum'],
  data () {
    return {
      loading: true,
      url: 'http://vjs.zencdn.net/v/oceans.mp4',
      // url: 'http://188.128.0.25:6092/live?rtmpport=1935&app=live&stream=5',
      // sources: '1',
      recordeFlag: false,
      MediaServerId: '',
      ChannelName: '',
      showMask: false // 遮罩
    }
  },
  watch: {
    mainid: {
      deep: true,
      handler (newurl, oldurl) {
        var videoElement = document.getElementById(this.id)
        // console.log("==-===-=newurl=" + newurl);
        // console.log("==-===-=oldurl=" + oldurl);
        // console.log("==-===-=id=" + this.id);
        if (videoElement) {
          if (!this.loading) {
            this.loading = true
          }
          if (this.player) {
            this.player.unload()
            this.player.detachMediaElement()
            this.player.destroy()
            this.player = null
          }
          this.initVedioUrl()
        }
      }
    }
  },
  computed: {},
  methods: {
    initVideo () {
      // console.log('videoMessage', this.videoMessage)
      if (flv.isSupported()) {
        const videoElement = document.getElementById('videoBox') // 根据video盒子id寻找
        this.player = flv.createPlayer({
          type: 'flv',
          url: this.videoMessage.PlayUrl[0],
          // url: 'http://188.128.0.25:6092/live?rtmpport=1935&app=live&stream=10',
          isLive: true,
          cors: true
        },
        {
          enableStashBuffer: false,
          stashInitialSize: 128,
          autoCleanupSourceBuffer: true
        }
        )
        this.player.attachMediaElement(videoElement)
        this.player.load() // 加载
        this.player.play() // 播放
      }
    },
    dblclick () {
      this.requestFullScreen(document.getElementById('videoBox'))
    },
    // 全屏
    requestFullScreen (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('monitors')
        var cssText = 'width:100%;height:100%;overflow:hidden;'
        docHtml.style.cssText = cssText
        docBody.style.cssText = cssText
        videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;'
        document.IsFullScreen = true
      }
    },
    fullScreen () {
      // 全屏展示
      // this.requestFullScreen(document.getElementById(this.id))
    },
    // 退出全屏
    exitFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.oRequestFullscreen) {
        document.oCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('monitors')
        docHtml.style.cssText = ''
        docBody.style.cssText = ''
        videobox.style.cssText = ''
        document.IsFullScreen = false
      }
    },
    leaveMask () {
      this.showMask = false
    }

  },
  created () { },
  mounted () {
    console.log('videoMessage', this.videoMessage)
    if (this.videoMessage.PlayUrl.length > 0) {
      this.initVideo()
    }
  }
}
</script>

<style scoped lang="less">
.videoBox {
  width: 100%;
  height: 240px;
  // background-color: green;
  background-image: url('~@/assets/img/mclz/cctvborder.png');
  background-size: 99% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  margin: 0 auto;

  .nosources {
    text-align: center;
    line-height: 230px;
    font-size: 16px;
    width: 430px;
    height: 220px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);

  }
  .videoPlace{
    .zhezhao{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      width: 89.5%;
      height: 40px;
      // margin-left: 20px;
      background-color: rgba(0,0,0,.6);
      text-align: center;
      line-height: 40px;
      padding: 5px;
      color: #666;
    }
  }
  .videosource {
    width: 430px;
    height: 220px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(121, 121, 121, 0.5);
  }
}

</style>
