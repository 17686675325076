<!-- 事件告警 -->
<template>
        <div class="detailsBox">
            <div class="leftImg">
                <el-image style="width: 100%; height: 100%" :src="item.capture_image"
                    :preview-src-list="formatImage(item.capture_image)">
                </el-image>
                <!-- <img :src="item.capture_image" alt=""> -->
            </div>
            <div class="rightDetails">
                <div class="flexbox">
                    <div class="lefticon detailicon1"></div><span>{{ item.scene_name }}</span>
                </div>
                <div class="flexbox">
                    <div class="lefticon detailicon2"></div><span>{{ item.alarm_chn_name }}</span>
                </div>
                <div class="flexbox">
                    <div class="lefticon detailicon3"></div><span>{{ item.alarm_type }}</span>
                </div>
                <div class="flexbox">
                    <div class="lefticon detailicon4"></div><span>{{ item.create_time }}</span>
                </div>
            </div>
        </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: ['item'],
  data () {
    return {
    }
  },
  watch: {},
  computed: {},
  methods: {},
  created () { },
  mounted () { }
}
</script>

<style scoped lang="less">
  .detailsBox {
                width: 100%;
                height: 137px;
                background-image: url('~@/assets/img/mclz/detailsbackground.png');
                background-size: 95% 110%;
                background-position: center;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                // padding: 0 30px;

                .leftImg {
                    width: 120px;
                    height: 105px;
                    padding: 0 20px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .rightDetails {
                    width: 250px;
                    height: 90px;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: space-evenly;

                    .flexbox {
                        display: flex;
                        text-align: center;
                        vertical-align: middle;

                        span {
                            text-align: center;
                            font-size: 14px;
                            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 24px;
                            margin-left: 5px;
                        }

                        .lefticon {
                            width: 24px;
                            height: 24px;
                        }

                        .detailicon1 {
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-image: url('~@/assets/img/mclz/issueicon1.png');
                        }

                        .detailicon2 {
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-image: url('~@/assets/img/mclz/issueicon2.png');
                        }

                        .detailicon3 {
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-image: url('~@/assets/img/mclz/issueicon3.png');
                        }

                        .detailicon4 {
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-image: url('~@/assets/img/mclz/issueicon4.png');
                        }
                    }

                }
            }</style>
