<!-- charts表格展示组件 -->
<template>
    <div class='content'>
        <topHeadSelect class="topNav" :selectBox='true' @getChangeYear="changeYear" @getChangeMonth="changeMonth" :isLight="isLight">告警排名</topHeadSelect>
        <div id="chartBox" v-if="!noResult"></div>
        <div v-else class="empty_text">暂无数据</div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import topHeadSelect from './topHeadSelect.vue'
import { getalarmRanking } from '@/api/mclzapi'
export default {
  name: 'ehartsBox',
  components: {
    topHeadSelect
  },
  props: { isLight: { type: Boolean } },
  data () {
    return {
      dataList: [],
      nameList: [], // 数据名
      numberList: [], // 数据值
      yearParams: '',
      monthParams: '',
      dateParams: {
        begintime: '',
        endtime: ''
      },
      noResult: false
    }
  },
  watch: {
    yearParams: {
      immediate: true,
      handler (newVal, oldVal) {
        this.dateParams.begintime = newVal + '-' + this.monthParams + '-' + '01' + ' ' + '00:00:00'
      }
    },
    monthParams: {
      immediate: true,
      handler (newVal, oldVal) {
        if (this.yearParams) {
          this.dateParams.begintime = this.yearParams + '-' + newVal + '-' + '01' + ' ' + '00:00:00'
        }
      }
    },
    dateParams: { // 时间变化时发起请求
      deep: true,
      handler (newVal) {
        console.log('newVal', newVal)
        if (this.yearParams !== 0) {
          if (this.monthParams === '') {
            newVal = {
              begintime: this.yearParams + '-01-31 00:00:00',
              endtime: this.yearParams + '-12-31 23:59:59'
            }
            this.fngetalarmRanking(newVal)
          } else if (this.yearParams % 4 === 0 && this.monthParams === '02') {
            newVal.endtime = this.yearParams + '-' + this.monthParams + '-' + '29' + ' ' + '23:59:59'
            this.fngetalarmRanking(this.dateParams)
          } else if (this.yearParams % 4 !== 0 && this.monthParams === '02') {
            newVal.endtime = this.yearParams + '-' + this.monthParams + '-' + '28' + ' ' + '23:59:59'
            this.fngetalarmRanking(this.dateParams)
          } else if (this.monthParams === '01' || this.monthParams === '03' || this.monthParams === '05' || this.monthParams === '07' || this.monthParams === '08' || this.monthParams === '10' || this.monthParams === '12') {
            newVal.endtime = this.yearParams + '-' + this.monthParams + '-' + '31' + ' ' + '23:59:59'
            this.fngetalarmRanking(this.dateParams)
          } else if (this.monthParams === '04' || this.monthParams === '06' || this.monthParams === '09' || this.monthParams === '11') {
            newVal.endtime = this.yearParams + '-' + this.monthParams + '-' + '30' + ' ' + '23:59:59'
            this.fngetalarmRanking(this.dateParams)
          }
        }
      }
    }
  },
  computed: {},
  methods: {
    // 获取告警排名
    async fngetalarmRanking (params) {
      const res = await getalarmRanking(params)
      // console.log('getalarmRanking', res.Data)
      if (res.Data) {
        this.selectRanking(res.Data)
        this.initEcharts(this.nameList, this.numberList)
      } else {
        this.noResult = true
      }
    },
    // 处理告警排名数据
    selectRanking (array) {
      const newList = []
      array.forEach(item => {
        newList.push({ value: item.total, name: item.scene_name })
      })
      this.dataList = []
      this.nameList = []
      this.numberList = []
      this.dataList = newList
      this.dataList.forEach(item => {
        this.nameList.push(item.name)
        this.numberList.push(item.value)
      })
    },
    initEcharts (nameList, numberList) {
      const chartDom = document.getElementById('chartBox')
      if (!chartDom) return
      const myChart = echarts.init(chartDom)
      var charts = { // 按顺序排列从大到小

        cityList: nameList,
        cityData: numberList
      }

      var top10CityList = charts.cityList
      var top10CityData = charts.cityData
      var color = [
        '#FDD56A',
        '#20D3AB',
        '#49CCFF']
      var color1 = [
        '#FDD56A',
        '#20D3AB',
        '#49CCFF']
      var color2 = [
        '#FDD56A22',
        '#20D3AB22',
        '#49CCFF22']

      const lineY = []
      const lineT = []
      for (var i = 0; i < charts.cityList.length; i++) {
        var x = i
        if (x > 1) {
          x = 2
        }
        var data = {
          name: charts.cityList[i],
          color: color[x],
          value: top10CityData[i],
          barGap: '-100%',
          itemStyle: {
            normal: {
              show: true,
              borderWidth: 2,
              shadowBlur: 1,
              borderColor: color2[x],
              shadowColor: color[x],
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                offset: 0,
                color: color[x]
              }, {
                offset: 1,
                color: color1[x]
              }], false),
              barBorderRadius: 10
            }
          }
        }
        var data1 = {
          color: color[x],
          value: top10CityData[i],
          barGap: '-100%',
          itemStyle: {
            color: '#00123500', // 剩余部分颜色
            barBorderRadius: 10
          },
          label: {
            show: true,
            formatter: '{a|' + top10CityData[i] + '次}',
            position: 'right',
            color: color[x],
            distance: 20,
            rich: {
              a: {
                color: color[x],
                // borderColor: color[x],
                // borderWidth: 0.5,
                padding: [5, 5, 0, 15],
                height: 18,
                align: 'right',
                verticalAlign: 'middle'
                // backgroundColor: color2[x]
              }
            }
          }
        }
        lineY.push(data)
        lineT.push(data1)
      }
      const option = {
        // backgroundColor: "#1F312F",
        title: {
          show: false
        },
        tooltip: {
          trigger: 'item',
          formatter: (p) => {
            if (p.seriesName === 'total') {
              return ''
            }
            return `${p.name}<br/>${p.value}`
          }
        },
        grid: {
          borderWidth: 0,
          top: '20px',
          left: '5%',
          right: '17%',
          bottom: '5%'
        },
        dataZoom: [{
          type: 'slider',
          yAxisIndex: 0,
          zoomLock: true,
          width: 5,
          height: '90%',
          handleSize: 0,
          handleStyle: {
            color: '#3AAAF0',
            borderColor: '#007acc'
          },
          fillerColor: 'rgba(27, 102, 177, 1)',
          backgroundColor: 'transparent',
          showDetail: false,
          borderColor: this.isLight ? '#666' : '#002944',
          start: 0,
          end: 30,
          endValue: 4, // 最多六个
          minValueSpan: 3, // 放大到最少几个
          maxValueSpan: 5 //  缩小到最多几个
        }, {
          type: 'inside',
          id: 'insideY',
          yAxisIndex: 0,
          start: 0,
          end: 50,
          zoomOnMouseWheel: true,
          moveOnMouseMove: false,
          moveOnMouseWheel: true,
          preventDefaultMouseMove: true
        }],
        color: color,
        yAxis: [{
          type: 'category',
          inverse: true,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false,
            inside: false
          },
          data: top10CityList
        }, {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            inside: false,
            verticalAlign: 'bottom',
            lineHeight: '20',
            textStyle: {
              color: '#fff',
              fontSize: '14',
              fontFamily: 'PingFangSC-Regular'
            },
            formatter: function (val) {
              return `${val}`
            }
          },
          splitArea: {
            show: false
          },
          splitLine: {
            show: false
          },
          data: top10CityData
        }],
        xAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [{
          name: 'total',
          type: 'bar',
          barGap: '-100%',
          barWidth: '15px',
          data: lineT,
          legendHoverLink: false
        }, {
          name: 'bar',
          type: 'bar',
          barWidth: '8px',
          data: lineY,
          label: {
            normal: {
              color: '#b3ccf8',
              show: true,
              position: [0, '-20px'],
              formatter: function (a) {
                let num = ''
                let str = ''
                if (a.dataIndex + 1 < 10) {
                  num = '0' + (a.dataIndex + 1)
                } else {
                  num = (a.dataIndex + 1)
                }
                if (a.dataIndex === 0) {
                  str = `{color1|${num}} {color4|${a.name}}`
                } else if (a.dataIndex === 1) {
                  str = `{color2|${num}} {color4|${a.name}}`
                } else {
                  str = `{color3|${num}} {color4|${a.name}}`
                }
                return str
              },
              rich: {
                color1: {
                  color: '#FDD56A',
                  fontSize: '14',
                  fontWeight: 700
                },
                color2: {
                  color: '#20D3AB',
                  fontSize: '14',
                  fontWeight: 700
                },
                color3: {
                  color: '#49CCFF',
                  fontSize: '14',
                  fontWeight: 700
                },
                color4: {
                  fontSize: '14',
                  fontWeight: 500,
                  color: '#fff'
                }
              }
            }
          }
        }]
      }
      option && myChart.setOption(option)
    },
    // 选择框年变化时
    changeYear (val) {
      this.yearParams = val
      this.monthParams = ''
    },
    changeMonth (val) {
      this.monthParams = val
    }
  },
  created () { },
  mounted () {
    this.fngetalarmRanking()
  }
}
</script>

<style scoped lang="less">
.content {
    width: 100%;
    height: 100%;
    .topNav{
        height: 40px;
    }
    #chartBox {
        width: 100%;
        height: 85%;
    }
    .empty_text{
      margin: 22% auto;
      color: white;
      font-size: 16px;
    }
}
</style>
