<!-- 事件告警轮播图 -->
<template>
    <div class='content'>
        <topHeadSelect class="topNav" :leftIcon="'anomaly'" :isLight="isLight">异常事件告警</topHeadSelect>
        <div class="footIssueBox" v-if="!noResource">
            <div class="ulbox">
                <ul class="innerBox">
                    <li v-for="(item, index) in animationList" :key="index" class="detailsBox">
                        <isuseAlarm :item="item"></isuseAlarm>
                        <!-- <div class="leftImg">
                            <el-image style="width: 100%; height: 100%" :src="item.capture_image"
                                :preview-src-list="formatImage(item.capture_image)">
                            </el-image>
                        </div>
                        <div class="rightDetails">
                            <div class="flexbox">
                                <div class="lefticon detailicon1"></div><span>{{ item.scene_name }}</span>
                            </div>
                            <div class="flexbox">
                                <div class="lefticon detailicon2"></div><span>{{ item.alarm_chn_name }}</span>
                            </div>
                            <div class="flexbox">
                                <div class="lefticon detailicon3"></div><span>{{ item.alarm_type }}</span>
                            </div>
                            <div class="flexbox">
                                <div class="lefticon detailicon4"></div><span>{{ item.create_time }}</span>
                            </div>
                        </div> -->
                    </li>
                </ul>
            </div>
        </div>
        <div v-else class="empty_text">暂无数据</div>
    </div>
</template>

<script>
import topHeadSelect from './topHeadSelect.vue'
import { getabnormalEventAlarm } from '@/api/mclzapi'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import isuseAlarm from './issueAlarm.vue'
export default {
  name: 'issueBox',
  components: {
    topHeadSelect, isuseAlarm
  },
  props: { isLight: { type: Boolean } },

  data () {
    return {
      issuesList: [],
      animationList: [],
      timeInterval: '',
      noResource: false // 暂无数据
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])
  },
  methods: {
    async fnGetabnormalEventAlarm () {
      const res = await getabnormalEventAlarm()
      this.issuesList = res.Data
      //   console.log('issue', res.Data)
      if (this.issuesList) {
        if (this.issuesList.length > 0) {
          this.animationList = this.issuesList // 注释掉动画后添加的
          this.animationList.forEach(element => {
            element.alarm_type = this.getWayName('AiboxAlarmType', parseInt(element.alarm_type))
            element.create_time = element.create_time.substring(0, 19).split('T').join(' ') // 处理时间
          })
        } else {
          this.noResource = true
        }
      } else {
        this.noResource = true
      }
    }

  },
  created () { },
  mounted () {
    this.fnGetabnormalEventAlarm()
    // 定时器发起数据查询
    this.timeInterval = setInterval(() => {
      this.fnGetabnormalEventAlarm()
    }, 10000)
  },
  destroyed () {
    clearInterval(this.timeInterval)
  }
}
</script>

<style scoped lang="less">
.content {
    width: 100%;
    height: 100%;
    position: relative;

    .topNav {
        height: 40px;
    }

    .footIssueBox {
        width: 100%;
        height: 95%;
        // overflow: hidden;
        overflow-y: scroll;

        position: relative;

        .ulbox {
            position: absolute;
        }

        .innerBox {
            width: 100%;
            height: 100%;
            overflow: hidden;
            padding: 0 10px;

            .detailsBox {
                width: 100%;
                height: 137px;
                background-image: url('~@/assets/img/mclz/detailsbackground.png');
                background-size: 95% 110%;
                background-position: center;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                // padding: 0 30px;

                .leftImg {
                    width: 120px;
                    height: 105px;
                    padding: 0 20px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .rightDetails {
                    width: 250px;
                    height: 90px;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: space-evenly;

                    .flexbox {
                        display: flex;
                        text-align: center;
                        vertical-align: middle;

                        span {
                            text-align: center;
                            font-size: 14px;
                            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 24px;
                            margin-left: 5px;
                        }

                        .lefticon {
                            width: 24px;
                            height: 24px;
                        }

                        .detailicon1 {
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-image: url('~@/assets/img/mclz/issueicon1.png');
                        }

                        .detailicon2 {
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-image: url('~@/assets/img/mclz/issueicon2.png');
                        }

                        .detailicon3 {
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-image: url('~@/assets/img/mclz/issueicon3.png');
                        }

                        .detailicon4 {
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-image: url('~@/assets/img/mclz/issueicon4.png');
                        }
                    }

                }
            }
        }
    }
}
.empty_text{
      margin: 80% auto;
      color: white;
      font-size: 16px;
    }
// 滚动条配置
/deep/::-webkit-scrollbar {
    width: 4px;
}

/deep/::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}

/deep/::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

/deep/ ::-webkit-scrollbar {
    /* 样式穿透 */
    display: none;
}</style>
