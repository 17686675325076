<!-- charts线图表格展示组件 -->
<template>
    <div class='content'>
        <topHeadSelect class="topNav" :selectBox='true' :leftIcon="'issues'" @getChangeYear="changeYear" @getChangeMonth="changeMonth" :isLight="isLight">异常事件趋势分析</topHeadSelect>
        <div id="chartLineBox" v-if="!noResource"></div>
        <div v-else class="empty_text">暂无数据</div>
        <!-- <div class="chooseBottonBox">
            <ul class="buttons">
                <li v-for="(item, index) in list" :key="index" :class="choosenLi === index?'innerBoxActive':'innerBox'" @click="chooseDate(index)">
                    {{ item }}
                </li>
            </ul>
        </div> -->
    </div>
</template>

<script>
import topHeadSelect from './topHeadSelect.vue'
import * as echarts from 'echarts'
import { getabnormalEventTrent } from '@/api/mclzapi'
import bottonImg from '@/assets/img/mclz/buttonbackground.png'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'chartLineBox',
  components: { topHeadSelect },
  props: { isLight: { type: Boolean } },
  data () {
    return {
      nameList: [], // 图例数据
      yearParams: '',
      monthParams: '',
      dateParams: { // 查询时间
        begintime: '',
        type: ''
      },
      seriesList: [], // 渲染表的折线数据
      noResource: false,
      type: '',
      yearXlist: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      monthXbiglist: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
      monthXsmalllist: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
      monthXferblist: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'],
      monthXferblist4: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29']
    }
  },
  watch: {
    yearParams: {
      handler (newVal, oldVal) {
        this.dateParams.begintime = newVal + '-' + this.monthParams + '-' + '01' + ' ' + '00:00:00'
        // console.log(this.dateParams.begintime)
      }
    },
    monthParams: {
      handler (newVal, oldVal) {
        this.dateParams.begintime = this.yearParams + '-' + newVal + '-' + '01' + ' ' + '00:00:00'
        // console.log(this.dateParams.begintime)
      }
    },
    dateParams: { // 时间变化时发起请求
      deep: true,
      handler (newVal) {
        if (this.monthParams === '' && this.yearParams !== '') {
          newVal = {
            begintime: this.yearParams + '-01-01 00:00:00',
            type: '1'
          }
          this.fngetabnormalEventTrent(newVal)
        } else if (this.monthParams !== '' && this.yearParams !== '') {
          newVal = {
            begintime: this.yearParams + '-' + this.monthParams + '-' + '01' + ' ' + '00:00:00',
            type: '2'
          }
          this.fngetabnormalEventTrent(newVal)
        }
        // console.log('params', newVal)
      }
    }
  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])

  },
  methods: {
    // 获取事件趋势
    async fngetabnormalEventTrent (params) {
      const nowMonth = this.yearXlist[parseInt(params.begintime.slice(5, 7)) - 1]
      const res = await getabnormalEventTrent(params)
      // console.log('getabnormalEventTrent', res)
      if (res.Data) {
        this.selectData(res.Data)
        this.refreshEcharts(this.seriesList[0].data.length, nowMonth)
      } else {
        this.noResource = true
      }
    },
    // 数据处理
    selectData (mix) {
      // console.log('mix', mix)
      this.nameList = []
      this.seriesList = []
      mix.forEach((item, index) => {
        // console.log('utilsgroup', item.group)
        this.nameList.push(this.getWayName('AiboxAlarmType', item.alarm_type))
        const monthTotal = []
        item.group.forEach(element => {
          monthTotal.push(element.total)
        })
        // console.log('monthTotal', monthTotal)
        this.seriesList.push({
          name: this.nameList[index],
          type: 'line',
          data: monthTotal
        })
      })
    },
    // 更新图表数据
    refreshEcharts (length, month) {
      if (length === 12) {
        this.initEcharts(this.nameList, this.seriesList, this.yearXlist, '')
      } else if (length === 28) {
        this.initEcharts(this.nameList, this.seriesList, this.monthXferblist, month)
      } else if (length === 29) {
        this.initEcharts(this.nameList, this.seriesList, this.monthXferblist4, month)
      } else if (length === 30) {
        this.initEcharts(this.nameList, this.seriesList, this.monthXsmalllist, month)
      } else if (length === 31) {
        this.initEcharts(this.nameList, this.seriesList, this.monthXbiglist, month)
      }
    },
    initEcharts (nameList, seriesList, xData, month) {
      const chartDom = document.getElementById('chartLineBox')
      if (!chartDom) return
      const myChart = echarts.init(chartDom)
      const option = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(50,50,50,0.7)',
          textStyle: {
            color: '#fff'
          },
          formatter: function (params) {
            var res = ''
            if (params[0].axisValue.endsWith('月')) {
              res = params[0].axisValue + '<br/>'
            } else {
              res = month + params[0].axisValue + '日' + '<br/>'
            }
            for (let i = 0; i < params.length; i++) {
              res += params[i].marker + params[i].seriesName + ':' + params[i].data + '<br/>'
            }
            return res
          }
        },
        legend: {
          bottom: '20',
          // itemWidth: '',
          // itemStyle: {
          //   // borderWidth: '2',
          //   // borderType: 'solid',
          //   // opacity: '0',

          // },
          textStyle: {
            color: 'orange',
            backgroundColor: {
              image: bottonImg
            },
            width: 10,
            height: 8,
            borderWidth: 10,
            padding: [10, 18]
          },
          icon: 'none',
          data: nameList
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '28%',
          top: '14%',
          containLabel: true
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisTick: 'false',
          axisLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            // x轴文字的配置
            show: true,
            interval: 0 // 使x轴文字显示全
          },
          data: xData
        },
        yAxis: {
          name: '单位：次',
          type: 'value',
          axisTick: 'false',
          axisLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'gray'
            }
          }
        },
        series: seriesList
      }
      option && myChart.setOption(option)
    },
    // 选择框年变化时
    changeYear (val) {
      this.yearParams = val
      this.monthParams = ''
    },
    // 选择框月变化时
    changeMonth (val) {
      this.monthParams = val
    }
  },
  created () { },
  mounted () {
    this.fngetabnormalEventTrent({ begintime: '2023-01-01 00:00:00', type: 1 })
  }
}
</script>

<style scoped lang="less">
.content {
    width: 100%;
    height: 100%;
    position: relative;

    .topNav {
        height: 40px;
    }

    #chartLineBox {
        width: 100%;
        height: 90%;
    }

    .chooseBottonBox {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 65px;
        padding: 5px;

        .buttons {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .innerBox {
                width: 110px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-image: url('~@/assets/img/mclz/buttonbackground.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
                font-size: 14px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #FFFFFF;
            }
            .innerBoxActive {
                width: 110px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-image: url('~@/assets/img/mclz/buttonactivebackground.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
                font-size: 14px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #FFFFFF;
            }
        }
    }
    .empty_text{
      margin: 12% auto;
      color: white;
      font-size: 16px;
    }

}</style>
