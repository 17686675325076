<!-- 数字加设备顶部小组件 -->
<template>
    <div :class="[isLight?'lightcontent':'content']">
        <div class="topNumber">
            <div :class="[isLight?'lightinnerText':'innerText']">
                <slot name="topNumber">10</slot>
            </div>
        </div>
        <div :class="[isLight?'lightfootName':'footName']">
            <slot name="footName">场景</slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'numberBox',
  components: {},
  props: { isLight: { type: Boolean } },
  data () {
    return {
    }
  },
  watch: {},
  computed: {},
  methods: {},
  created () { },
  mounted () { }
}
</script>

<style scoped lang="less">
.lightcontent{
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/img/mclz/light-numberboxbackground.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.content {
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/img/mclz/numberboxbackground.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-repeat: no-repeat;

    .topNumber {
        width: 100%;
        height: 35px;
        .lightinnerText{
            text-align: center;
            line-height: 35px;
            font-family: YouSheBiaoTiYuan;
color: #333333;
font-size: 30px;
        }
        .innerText {
            text-align: center;
            line-height: 35px;
            font-size: 30px;
            font-family: YouSheBiaoTiYuan;
            color: #01A5FA;
            background-clip: text;
            background-image: linear-gradient(to bottom, #00FFF5, #FFFFFF);
            // background: radial-gradient(NaNdeg, #FFFFFF 0%, #00FFF5 0%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

    }

    .footName {
        text-align: center;
        width: 100%;
        height: 35px;
        line-height: 35px;
        font-size: 18px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
    }
    .lightfootName{
        text-align: center;
        width: 100%;
        height: 35px;
        line-height: 20px;
        font-size: 18px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #666;
    }
}
</style>
