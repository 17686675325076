<!-- 视频监控显示组件 -->
<template>
  <div class='content'>
    <topHeadSelect class="topNav" :leftIcon="'cctv'" :isLight="isLight">视频监控</topHeadSelect>
    <div class="block">
    <el-carousel height="240px"  indicator-position="none" :autoplay=false arrow="never" v-if="!noResult">
      <el-carousel-item v-for="(item,index) in cctvList" :key="index">
        <videoBox :videoMessage="item" :idnum="index"></videoBox>
      </el-carousel-item>
    </el-carousel>
    <div v-else class="empty_text">暂无数据</div>
  </div>
  </div>
</template>

<script>
import topHeadSelect from './topHeadSelect.vue'
import videoBox from './videoBox.vue'
import { getcctv } from '@/api/mclzapi'

export default {
  name: 'cctvBox',
  components: {
    topHeadSelect,
    videoBox
  },
  props: { isLight: { type: Boolean } },
  data () {
    return {
      cctvList: [],
      noResult: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    // 获取监控数据
    async fngetcctv (type) {
      const res = await getcctv(type)
      this.cctvList = res.Data
      // console.log('cctv', res.Data)
      if (!res.Data) {
        this.noResult = true
      }
    }
  },
  created () { },
  mounted () {
    this.fngetcctv()
  }

}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;

  .topNav {
    height: 40px;
  }
  .empty_text{
      margin: 22% auto;
      color: white;
      font-size: 16px;
    }

}

</style>
