<!-- charts饼图表格展示组件 -->
<template>
    <div class='content'>
        <topHeadSelect class="topNav" :selectBox='false' :leftIcon="'warning'" :isLight="isLight">告警类型</topHeadSelect>
        <div id="chartPieBoxAlarm" v-if="!noResource"></div>
        <div class="imgBox" v-if="!noResource"></div>
        <div v-if="noResource" class="empty_text">暂无数据</div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import topHeadSelect from './topHeadSelect.vue'
import baseIcon from '@/assets/baseimg/base64Pic'
import { getexceptionEvents } from '@/api/mclzapi.js'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'chartPieBox',
  components: {
    topHeadSelect
  },
  props: { isLight: { type: Boolean } },
  data () {
    return {
      dataList: [],
      numList: [],
      noResource: false
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])

  },
  methods: {
    // 获取告警类型
    async fngetexceptionEvents () {
      const res = await getexceptionEvents()
      const alarmName = []
      const alarmtotal = []
      console.log('getexceptionEvents', res.Data)
      if (res.Data) {
      // this.getWayName('AiboxAlarmType', item.alarm_)
        res.Data.forEach(item => {
          alarmName.push(this.getWayName('AiboxAlarmType', parseInt(item.alarm_type)))
          alarmtotal.push(item.total)
        })
        const selectAlarming = {
          nameList: alarmName,
          dataList: alarmtotal
        }
        // console.log('selectAlarming', selectAlarming)
        const dataList = []
        for (var i = 0; i <= selectAlarming.dataList.length - 1; i++) {
          dataList.push({ name: selectAlarming.nameList[i], value: selectAlarming.dataList[i] })
        }
        // this.initEcharts(selectAlarming.nameList, selectAlarming.dataList)
        this.initEcharts(dataList)
      } else {
        this.noResource = true
      }
    },
    initEcharts (dataList) {
      const chartDom = document.getElementById('chartPieBoxAlarm')
      if (!chartDom) return
      const myChart = echarts.init(chartDom)
      const data = dataList
      const option = {
        tooltip: {
          formatter: (param) => {
            return `${param.name}：${param.value}次 (${param.percent}%)`
          },

          borderColor: '#0F5796',
          borderWidth: '3',
          padding: [5, 5, 5, 5],
          textStyle: {
            color: '#FFf',
            fontWeight: 'bold',
            fontSize: 14

          }
        },
        legend: {
          icon: 'circle',
          orient: 'horizontal',
          x: '70%',
          top: 'center',
          width: 80,
          itemWidth: 10,
          itemGap: 10,
          align: 'left',
          itemStyle: {
            borderColor: 'gray',
            borderWidth: 2
          },
          textStyle: {
            color: '#fff'
          }
        },
        series: [{
          name: '',
          type: 'pie',
          radius: ['45%', '60%'],
          center: ['35%', '50%'], // 图形位置
          color: ['RGBA(0, 96, 214, 1)', 'RGBA(1, 166, 252, 1)', 'RGBA(1, 250, 253, 1)', 'RGBA(30, 230, 163,1)', 'RGBA(252, 177, 45,1)', 'RGBA(239, 95, 71, 1)', 'RGBA(255, 39, 141, 1)', 'RGBA(255, 39, 141, 1)', 'RGBA(0, 255, 180, 1)'],

          grid: {
            left: '0',
            top: 0,
            right: '0',
            bottom: 0
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '14',
              fontWeight: 'bold',
              formatter: ['{a|{b}\n{d}%}'].join('\n'),
              rich: {
                a: {
                  fontSize: 14,
                  lineHeight: 20,
                  color: '#fff'
                },
                b: {
                  color: '#a7a9c7',
                  fontSize: 14,
                  lineHeight: 10
                }
              }
            }
          },
          label: {
            show: false,
            position: 'center',
            color: '#fff'
          },
          labelLine: {
            show: false
          },
          avoidLabelOverlap: true,
          // labelLine: {
          //   normal: {
          //     show: true,
          //     length: 30,
          //     length2: 40,
          //     lineStyle: {
          //       color: '#fff',
          //       width: 2
          //     },
          //     minTurnAngle: 0,
          //     maxSurfaceAngle: 90
          //   },
          //   itemStyle: {
          //     borderCap: 'round'
          //   }
          // },
          // label: {
          //   normal: {
          //     position: 'outside',
          //     formatter: '\n{b|{b}}{c|{c}次}\n{d|{d}%}\n',
          //     padding: [12, -50, 12, -70],
          //     rich: {
          //       b: {
          //         fontSize: 10,
          //         color: 'RGBA(252, 177, 45,1)',
          //         align: 'left',
          //         padding: [10, 0, 10, 10]

          //       },
          //       d: {
          //         fontSize: 10,
          //         color: '#00FFB3',
          //         align: 'left',
          //         padding: [5, 5, 10, 10]

          //       },
          //       c: {
          //         fontSize: 10,
          //         color: 'RGBA(252, 177, 45,1)',
          //         align: 'left'
          //       }
          //     }
          //   }
          // },

          data: data
        }],
        graphic: [
          {
            type: 'group',
            left: '24.5%',
            top: 'center',
            children: [
              {
                id: 'innerPic',
                type: 'image',
                style: {
                  width: 100,
                  height: 100
                  // image: baseIcon.piePic.pieInnerPic
                }
              }

            ]
          }
        ]
      }
      option && myChart.setOption(option)
    }
  },
  created () { },
  mounted () {
    this.fngetexceptionEvents()
  }
}
</script>

<style scoped lang="less">
.content {
    width: 100%;
    height: 100%;
    position: relative;
    .topNav {
        height: 40px;
    }

    #chartPieBoxAlarm {
        width: 100%;
        height: 90%;
    }
    .imgBox{
      position: absolute;
      width: 95px;
      height: 95px;
      top: 118px;
      left: 117px;
      background-image: url('~@/assets/img/mclz/pieborder.png');
      background-size: 100%;
      animation: rotate 3s linear infinite;
    }
    .empty_text{
      margin: 22% auto;
      color: white;
      font-size: 16px;
    }
}
@keyframes rotate {
    0%{-webkit-transform:rotate(0deg);}
    25%{-webkit-transform:rotate(90deg);}
    50%{-webkit-transform:rotate(180deg);}
    75%{-webkit-transform:rotate(270deg);}
    100%{-webkit-transform:rotate(360deg);}
}

</style>
