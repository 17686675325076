<!-- 顶部标题加选择框组件 -->
<template>
  <div :class="[isLight ? 'contentLight' : 'content']">
    <div class="topHead">
      <div :class="[isLight ? 'lightleftName' : 'leftName']">
        <div class="ranking"></div>
        <slot>标题</slot>
      </div>
      <div class="rightSelect" v-show="selectBox">
        <el-select :class="[isLight ? 'lightSelect' : 'select']" v-model="yearValue" placeholder="请选择" size="medium"
          @change="changeYear" :append-to-body="false">
          <el-option v-for="item in yearOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select :class="[isLight ? 'lightSelect' : 'select']" v-model="monthValue" placeholder="请选择" size="medium"
          @change="changeMonth">
          <el-option v-for="item in monthOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'topHeadSelect',
  components: {},
  props: {
    selectBox: { default: false, type: Boolean }, // 时间选择器
    leftIcon: { default: 'ranking' },
    isLight: { type: Boolean, default: false }
  },
  data () {
    return {
      yearOptions: [{
        value: '2021',
        label: '2021'
      }, {
        value: '2022',
        label: '2022'
      }, {
        value: '2023',
        label: '2023'
      }],
      monthOptions: [{
        value: '01',
        label: '一月'
      }, {
        value: '02',
        label: '二月'
      }, {
        value: '03',
        label: '三月'
      }, {
        value: '04',
        label: '四月'
      }, {
        value: '05',
        label: '五月'
      }, {
        value: '06',
        label: '六月'
      }, {
        value: '07',
        label: '七月'
      }, {
        value: '08',
        label: '八月'
      }, {
        value: '09',
        label: '九月'
      }, {
        value: '10',
        label: '十月'
      }, {
        value: '11',
        label: '十一月'
      }, {
        value: '12',
        label: '十二月'
      }
      ],
      yearValue: '',
      monthValue: ''
    }
  },
  watch: {
  },
  computed: {
    ...mapState(['isFullScreen'])

  },
  methods: {
    // 选择框时间变化时传递给父级时间数据
    changeYear (val) {
      this.$emit('getChangeYear', val)
      this.monthValue = ''
    },
    changeMonth (val) {
      this.$emit('getChangeMonth', val)
    }

  },
  created () { },
  mounted () {
    this.yearValue = new Date().getFullYear()
    this.monthValue = new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1)
    this.changeYear(this.yearValue)
    this.changeMonth(this.monthValue)
  }
}
</script>

<style scoped lang="less">
.contentLight {
  height: 100%;
  width: 100%;
  padding: 0 5px;
  background-color: #fff;
  box-sizing: border-box;
}

.content {
  height: 100%;
  width: 100%;
  padding: 0 5px;
  background-image: url('~@/assets/img/mclz/topnavbackground.png');
  background-size: 101.5% 112%;
  background-position: top left;
  background-repeat: no-repeat;
  box-sizing: border-box;

  .topHead {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 3px 5px;
    box-sizing: border-box;

    .lightleftName {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #01A5FA;
      line-height: 35px;
      background-image: linear-gradient(to top, #1068FB, #0FABFB);
      background-clip: text;
      -webkit-background-clip: text;
      background-clip: text;

      // background: radial-gradient(NaNdeg, #1068FB 0%, #0FABFB 0%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }

    .leftName {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 35px;
      background: linear-gradient(NaNdeg, #07477D 0%, #075359 0%);
    }

    .rightSelect {
      height: 100%;
      display: flex;

      .lightSelect {
        background-image: url('~@/assets/img/mclz/light-selectbackground.png');
        background-size: 100% 100%;
        background-position: center center;

        .el-select .el-input .el-input__inner {
          color: #4C4C4C !important;
        }
      }

      .select {
        // background-color: green;
        background-image: url('~@/assets/img/mclz/selectbackground.png');
        background-size: 100% 100%;
        background-position: center center;
       .el-select .el-input .el-input__inner {
          color:#fff !important;
        }

      }

      /deep/.el-icon-arrow-up:before {
        content: "\e790" !important;
        color: #0FD3FF;
        width: 5px;
        height: 5px;
      }
    }
  }
}

.ranking {
  width: 35px;
  height: 35px;
  background-image: url('~@/assets/img/mclz/topHeadIcon.png');
  background-position: center center;
  background-size: 100% 100%;
}

.cctv {
  width: 35px;
  height: 35px;
  background-image: url('~@/assets/img/mclz/cctvicon.png');
  background-position: center;
  background-size: 100% 100%;
}

.warning {
  width: 35px;
  height: 35px;
  background-image: url('~@/assets/img/mclz/warningicon.png');
  background-position: center;
  background-size: 100% 100%;
}

.anomaly {
  width: 35px;
  height: 35px;
  background-image: url('~@/assets/img/mclz/anomalyicon.png');
  background-position: center;
  background-size: 100% 100%;
}

.issues {
  width: 35px;
  height: 35px;
  background-image: url('~@/assets/img/mclz/issueicon.png');
  background-position: center;
  background-size: 100% 100%;
}

/deep/.el-select-dropdown.el-popper {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

/deep/.el-popper[x-placement^=bottom] .popper__arrow::after {
  border-bottom-color: rgba(0, 0, 0, 0.8) !important;
}

/deep/.el-select-dropdown__item {
  color: #ffffff !important;
  font-weight: 400;
}

/deep/.el-select-dropdown__item.selected {
  color: #409EFF !important;
  font-weight: 700;
}

/deep/.el-select-dropdown__item.hover,
/deep/.el-select-dropdown__item:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

/deep/.el-select .el-input .el-select__caret {
  color: #ffffff !important;
}
/deep/.el-select .el-input .el-input__inner {
          // color: #ffffff;
          border: none;
          background: transparent;
          width: 90px;
          height: 40px;
          text-align: right;
          font-size: 14px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
        }
</style>
