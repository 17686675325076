import axios from './http'
// 异常事件概览数据
export const getexceptionEvents = () => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetExceptionEventsTotal'
  })
}
// 告警排名
export const getalarmRanking = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetAlarmRanking',
    params
  })
}
// 获取视屏监控
export const getcctv = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/Screen_NVR_Monitor',
    params
  })
}
// 获取设备状态
export const getequipmentStatus = () => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetDeviceStatus'
  })
}
// 异常事件趋势分析
export const getabnormalEventTrent = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetEventsTrendAnalysis',
    params
  })
}
// 异常事件告警前10条
export const getabnormalEventAlarm = () => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetAlarmTop10'
  })
}
// 获取统计数量(场景，设备，抓拍记录)
export const getStatistical = () => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetStatistical'
  })
}
// 获取场景经纬度列表
export const getLocationMap = () => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetSceneList'
  })
}
